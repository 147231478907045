import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFView,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  aboutSurrey,
  mobileAboutSurrey,
  aboutLangley,
  mobileAboutLangley,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let AboutMobileImage = null
        let AboutMobileImage2 = null
        let AboutImage = null
        if (locationId === 'lvaE9GmmlgJQ40VW1JqK') {
          AboutImage = aboutLangley
          AboutMobileImage = mobileAboutLangley
        } else {
          AboutImage = aboutSurrey
          AboutMobileImage = mobileAboutSurrey
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage
                  src={AboutMobileImage}
                  w="100%"
                  alt="aboutImageMobile"
                />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView row justifyStart w="100%" maxWidth="1400px">
                <CFImage src={AboutImage} w="100%" alt="about Image" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
