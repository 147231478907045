import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
  LocationSelect,
} from 'components'
import OrderPickupButton from './OrderPickupButton'
import {
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
  locationButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
        >
          <CFView textCenter column center w="90%">
            <CFImage
              w="100%"
              src={mobileHeroText}
              alt="Damiko Sushi Logo Title"
            />
            <CFView mt="25px">
              <LocationSelect />
            </CFView>
            <CFView mt="5px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          center
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView column center w="100%" maxWidth="1400px">
            <CFImage
              w="60%"
              maxWidth="640px"
              src={heroText}
              alt="Damiko Sushi Logo Title"
            />
            <CFView mt="25px">
              <LocationSelect />
            </CFView>
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
